import React from 'react';
import { Container } from 'reactstrap';
import { Icon } from '../../UI/atoms/Icon';

class Footer extends React.Component {
  state = {
    isLoader: false,
  };

  render() {
    return (
      <footer>
        <Container>
          <span className="copyright">Copyright 2019 © Kids Up Front. All Rights Reserved.</span>
          <ul>
            <li>
              <a href="foo" onClick={e => {
                // emmpty function
    }}>
                {' '}
                <Icon name="facebook" />{' '}
              </a>
            </li>
            <li>
              <a href="foo" onClick={e => {
                // emmpty function
    }}>
                {' '}
                <Icon name="twitter" />{' '}
              </a>
            </li>
            <li>
              <a href="foo" onClick={e => {
                // emmpty function
    }}>
                {' '}
                <Icon name="instagram" />{' '}
              </a>
              >
            </li>
            <li>
              <a href="foo" onClick={e => {
                // emmpty function
    }}>
                {' '}
                <Icon name="youtube" />{' '}
              </a>
            </li>
            <li>
              <a href="foo" onClick={e => {
                // emmpty function
    }}>
                {' '}
                <Icon name="pinterest" />{' '}
              </a>
              >
            </li>
          </ul>
        </Container>
      </footer>
    );
  }
}

export default Footer;
