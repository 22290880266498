/*eslint eqeqeq: "off"*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import GuestMain from '../templates/guest/GuestMain';
import UserMain from '../templates/user/UserMain';
import { withRouter } from 'react-router-dom';
import { fetchUser, loginToken } from '../../actions/AuthActions';
import {
  APP_DYNAMICS_RESOURCE,
  DYNAMICS_CLIENT_ID,
  DYNAMICS_CLIENT_SECRET,
  DYNAMICS_GRANT_TYPE,
} from '../../utils/constants';

export default function (ComposedComponent) {
  class Wrapper extends Component {
    componentDidMount(nextProps) {
      // console.log('auth require', this.props);
      var backend_token = localStorage.getItem('token');
      if (backend_token) {
        var payload = { token: backend_token };
        // this.props.fetchUserFn(payload);
      }
      let param = {
        client_id: DYNAMICS_CLIENT_ID,
        grant_type: DYNAMICS_GRANT_TYPE,
        client_secret: DYNAMICS_CLIENT_SECRET,
        resource: APP_DYNAMICS_RESOURCE,
      };
      this.props.tokenUserFn(param);

      setInterval(() => {
        this.props.tokenUserFn(param);
      }, 300000)
    }

    render() {
      if (window.location.pathname !== "/") {
        return <UserMain children={<ComposedComponent {...this.props} />} />;
      } else {
        return <GuestMain children={<ComposedComponent {...this.props} />} />;
      }
      // return <UserMain children={<ComposedComponent {...this.props} />} />
    }
  }

  function mapStateToProps(state) {
    return { user: state.authReducer.user };
  }

  const mapDispatch = (dispatch) => ({
    fetchUserFn: (user) => dispatch(fetchUser(user)),
    tokenUserFn: (dynamic) => dispatch(loginToken(dynamic)),
  });

  return withRouter(connect(mapStateToProps, mapDispatch)(Wrapper));
}
