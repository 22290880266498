import { INIT, SUCCESS, ERROR } from '../utils/constants';

const initialState = {
  phase: localStorage.getItem('phase') ? localStorage.getItem('phase') : INIT,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null,
  dynamic: null,
  error: null,
  email_exist: null,
  submit_agency_code: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INITIAL_AUTH_NEW':
      localStorage.clear();

      return {
        phase: INIT,
        user: null,
        error: null,
      };

    case 'USER_LOGIN':
      let storeObject = {
        phase: SUCCESS,
        user: action.payload,
        error: null,
      };

      if (localStorage.getItem('phase') == null || localStorage.getItem('user') == null) {
        //localStorage.setItem('phase', action.payload.status);
        localStorage.setItem('token', action.payload.data.token);
      }

      return storeObject;

    case 'USER_FRESH_DETAILS':
      let storeObject1 = {
        phase: SUCCESS,
        user: action.payload,
        error: null,
      };

      return storeObject1;

    case 'USER_UPDATE':
      let actionData = {};
      if (action.payload && action.payload.id) {
        actionData = action.payload;
      }
      if (action.payload && action.payload.data && action.payload.data.id) {
        actionData = action.payload['data'];
      }
      state.user.data['user'] = actionData;
      let localData = JSON.parse(localStorage.getItem('user'));
      localData.user.data['user'] = actionData;
      localStorage.setItem('user', JSON.stringify(localData));

      return {
        ...state,
        phase: SUCCESS,
        user: state.user,
        error: null,
      };

    case 'USER_LOGIN_SUCCESS':
      return {
        ...state,
        phase: SUCCESS,
        user: action.payload,
        error: null,
      };

    case 'USER_LOGIN_ERROR':
      return {
        ...state,
        phase: ERROR,
        error: action.error,
      };

    case 'CLEAR_ERROR': {
      return {
        ...state,
        error: null,
      };
    }

    case 'USER_ADD_ADDRESS':
      return {
        ...state,
        user: {
          ...state.user,
          message: {
            ...state.user.data,
            user: {
              ...state.user.data.user,
              roleProfile: {
                ...state.user.data.user.roleProfile,
                address: action.payload.length ? action.payload[0] : null,
              },
            },
          },
        },
      };
    case 'LOGIN_TOKEN':
      let storeLoginObject = {
        ...state,
        dynamic: action.payload,
      };
      localStorage.setItem('dynamic_token', action.payload.access_token);

      return storeLoginObject;
    case 'UPDATE_USER_AFTER_DYNAMICS':
      let storeUpdateObject = {
        ...state,
        update_success: action.payload,
      };
      return storeUpdateObject;

    case 'STORE_DYNAMICS_USER_DETAILS':
      let storeDynamicObject = {
        ...state,
        dynamic_user_detail: action.payload,
      };
      return storeDynamicObject;

    case 'SUCCESS_CHECK_EMAIL':
      return {
        ...state,
        email_exist: action.response,
      };
    case 'ERROR_CHECK_EMAIL':
      return {
        ...state,
        email_exist: action.response,
      };
    case 'SUBMIT_AGENCY_CODE':
      state.submit_agency_code = action.payload;
      return { ...state };


    default:
      return state;
  }
};

export default authReducer;
