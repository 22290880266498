/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";
import { getAllEvents, getAllEventsSorted, getEventCategories, submitRequest, getFeaturedCategories } from "../../../../actions/EventActions";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css'; //Allows for server-side rendering.
import moment from "moment";
import { Container, Row, Col } from 'reactstrap';
import { FormGroup } from 'reactstrap';
import EventCalendar from './eventCalendar';
// import FeaturedEvents from './featuredEvents';
import EventsCategories from './eventCategories';
import SearchInput from '../../../UI/atoms/searchBox'
import { DefaultButton } from '../../../UI/atoms/index';
import { Icon } from './../../../UI/atoms/Icon';
import randomImages from '../../../../assets/randomImages'
import { get } from 'lodash'

class EventsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      allEvents: null,
      openModal: false,
      allEventsList: {},
      resetFilter: false,
      allCatgoriesChecked: [],
      getEventCategories: [],
      submitRequestData: {},
      googleCapture: "",
      allElements: {
        // city: {
        //   operator: '',
        //   filter: 'evtech_city',
        //   eq: 'eq',
        //   id: localStorage.getItem('eventCity')
        // },
        _owningbusinessunit_value: {
          operator: '',
          filter: '_owningbusinessunit_value',
          eq: 'eq',
          id: get(this.props.business_unit, '[0].owningbusinessunit.businessunitid')
        },
        displayonweb: {
          operator: 'and',
          filter: 'cr676_display_on_experience_hub',
          eq: 'eq',
          id: true
        }
      },
      images: [
        { name: "Event 1", url: "https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 2", url: "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 3", url: "https://images.pexels.com/photos/1047442/pexels-photo-1047442.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 4", url: "https://images.pexels.com/photos/1684187/pexels-photo-1684187.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 5", url: "https://images.pexels.com/photos/1449791/pexels-photo-1449791.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 6", url: "https://images.pexels.com/photos/1190297/pexels-photo-1190297.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 7", url: "https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 8", url: "https://images.pexels.com/photos/2774556/pexels-photo-2774556.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 9", url: "https://images.pexels.com/photos/2608517/pexels-photo-2608517.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 10", url: "https://images.pexels.com/photos/2399097/pexels-photo-2399097.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 11", url: "https://images.pexels.com/photos/3023317/pexels-photo-3023317.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 12", url: "https://images.pexels.com/photos/110472/pexels-photo-110472.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 1", url: "https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 2", url: "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 3", url: "https://images.pexels.com/photos/1047442/pexels-photo-1047442.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 4", url: "https://images.pexels.com/photos/1684187/pexels-photo-1684187.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 5", url: "https://images.pexels.com/photos/1449791/pexels-photo-1449791.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 6", url: "https://images.pexels.com/photos/1190297/pexels-photo-1190297.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 7", url: "https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 8", url: "https://images.pexels.com/photos/2774556/pexels-photo-2774556.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 9", url: "https://images.pexels.com/photos/2608517/pexels-photo-2608517.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 10", url: "https://images.pexels.com/photos/2399097/pexels-photo-2399097.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 11", url: "https://images.pexels.com/photos/3023317/pexels-photo-3023317.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 12", url: "https://images.pexels.com/photos/110472/pexels-photo-110472.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 1", url: "https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 2", url: "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 3", url: "https://images.pexels.com/photos/1047442/pexels-photo-1047442.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 4", url: "https://images.pexels.com/photos/1684187/pexels-photo-1684187.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 5", url: "https://images.pexels.com/photos/1449791/pexels-photo-1449791.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 6", url: "https://images.pexels.com/photos/1190297/pexels-photo-1190297.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 7", url: "https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 8", url: "https://images.pexels.com/photos/2774556/pexels-photo-2774556.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 9", url: "https://images.pexels.com/photos/2608517/pexels-photo-2608517.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 10", url: "https://images.pexels.com/photos/2399097/pexels-photo-2399097.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 11", url: "https://images.pexels.com/photos/3023317/pexels-photo-3023317.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 12", url: "https://images.pexels.com/photos/110472/pexels-photo-110472.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" }
      ]
    }
  }



  callFilter = (elem) => {
    let { allCatgoriesChecked } = this.state;
    this.props.getAllEventsSorted(elem, allCatgoriesChecked);
  }

  resetFilter = () => {
    let { allElements } = this.state;
    allElements = {
      // city: {
      //   operator: '',
      //   filter: 'evtech_city',
      //   eq: 'eq',
      //   id: localStorage.getItem('eventCity')
      // },
      _owningbusinessunit_value: {
        operator: '',
        filter: '_owningbusinessunit_value',
        eq: 'eq',
        id: get(this.props.business_unit, '[0].owningbusinessunit.businessunitid')
      },
      displayonweb: {
        operator: 'and',
        filter: 'cr676_display_on_experience_hub',
        eq: 'eq',
        id: true
      }

    }
    this.setState({ allElements, resetFilter: true, allCatgoriesChecked: [], date: '' }, () => { this.callFilter(allElements) });
  }

  onChange = (date) => {
    let { allElements } = this.state;
    allElements.date = {
      operator: 'and',
      filter: '( evtech_startdate',
      eq: 'ge',
      id: moment(date).format('YYYY-MM-DD'),

      operator2: 'and',
      filter2: 'evtech_startdate',
      eq2: 'lt',
      id2: moment(date).add(1, 'days').format('YYYY-MM-DD'),

      operator3: 'or',
      filter3: '(evtech_startdate',
      eq3: 'lt',
      id3: moment(date).format('YYYY-MM-DD'),

      operator4: 'and',
      filter4: 'evtech_enddate',
      eq4: 'gt',
      id4: moment(date).format('YYYY-MM-DD') + '))'
    };
    this.setState({ resetFilter: false })
    this.setState({ date, allElements }, () => { this.callFilter(allElements) });
  }

  sortByCategory = (allCatgories) => {
    this.setState({ resetFilter: false })
    let { allElements } = this.state;
    this.setState({ allCatgoriesChecked: allCatgories, allElements }, () => {
      this.callFilter(allElements, allCatgories)
    });
  }

  componentDidMount() {
    this.props.getEventCategoriesFn();
    if (get(this.props.business_unit, '[0].owningbusinessunit.businessunitid'))
      this.props.getFeaturedCategories(this.props.business_unit[0].owningbusinessunit.businessunitid);
    let { allElements } = this.state;
    this.callFilter(allElements);
    //this.randomhPhotos();
  }

  componentDidUpdate() {
    const { get_event_categories, submit_request } = this.props
    const { get_event_categories_State, allEventsList, submitRequestData } = this.state

    if (get_event_categories !== get_event_categories_State) {
      let getEventCategoriesList = []
      get_event_categories.length > 0 && get_event_categories.map((event) => {
        getEventCategoriesList.push({
          value: event.code,
          label: event.name
        })
        return true
      })

      this.setState({
        get_event_categories_State: get_event_categories,
        getEventCategories: getEventCategoriesList
      })
    }

    if (submit_request !== submitRequestData) {
      // console.log('submitRequestData',submitRequestData, submit_request)
      this.setState({
        submitRequestData: submit_request,
        openModal: false
      })
    }

    // if (previousProps !== this.props) {
    // if (Array.isArray(this.props.get_all_events) && this.props.get_all_events.length > 0) {
    //   // this.setState({ allEvents: this.props.get_all_events })
    // }
    if (allEventsList !== this.props.get_sorted_events) {
      if (Array.isArray(this.props.get_sorted_events) && this.props.get_sorted_events.length >= 0) {
        this.setState({
          allEvents: this.props.get_sorted_events,
          allEventsList: this.props.get_sorted_events

        })
      }
      // }
    }
  }



  featureEvents = () => {
    //console.log("I am in Props",this.props);
    let values = [];
    let eventImage = "";

    if (Array.isArray(this.props.get_featured_events) && this.props.get_featured_events.length > 0) {
      this.props.get_featured_events.map((event, index) => {
        if (Object.keys(event).length !== 0) {
          eventImage = event && event.cr676_image ? `data:image/jpeg;base64,${event.cr676_image}` : randomImages[event.evtech_eventcategory] ? randomImages[event.evtech_eventcategory][index % randomImages[event.evtech_eventcategory].length] : randomImages.random[index % randomImages.random.length];
        }
        values.push(
          <div key={index} >
            <div className="image-outer"> <img src={eventImage} alt={event.evtech_name} /></div>
            <div className="event-des">
              <h5>{event.evtech_name}</h5>
              <p><Icon name="calendar" /> {event.evtech_startdate ? moment.utc(event.evtech_startdate).format("MMM DD, YYYY") : ''} {event.evtech_enddate ? "- " + moment.utc(event.evtech_enddate).format("MMM DD, YYYY") : ''}
              </p>
              <p><Icon name="clock-o" /> {event.evtech_startdate ? moment.utc(event.evtech_startdate).format("hh:mm A") : ''} {event.evtech_enddate ? "- " + moment.utc(event.evtech_enddate).format("hh:mm A") : ''}</p>
              <p><Icon name="map-marker" /> {event.evtech_venueid ? event.evtech_venueid.evtech_name : ''}</p>

              {/* <p>{event.evtech_description}</p> */}
            </div>
            <Link to={{ pathname: `event-detatil/${event.evtech_eventid}`, state: { eventImage } }}> <DefaultButton name="Read More" color="link"
            > </DefaultButton> </Link>
          </div>
        )
        return true
      })
      return (
        <>
          <h3>Featured Events</h3>
          <OwlCarousel options={{
            items: 3,
            nav: true,
            margin: 20,
            loop: true,
            responsiveClass: true,
            responsive: {
              0: {
                items: 1, // from this breakpoint 678 to 959
                center: true
              },
              678: {
                items: 1, // from this breakpoint 678 to 959
                center: true // only within 678 and next - 959
              },

              960: {
                items: 2, // from this breakpoint 960 to 1199
                margin: 20, // and so on...
                center: false

              },

              1200: {
                items: 3,
                loop: false,
                margin: 30,
              }
            }
          }} >
            {values}
          </OwlCarousel>
        </>
      );
    }
  }

  allEvents = () => {
    const { allEvents } = this.state;
    let eventImage = "";
    let values = [];
    if (Array.isArray(allEvents) && allEvents.length >= 0) {
      allEvents.map((event, index) => {
        //  const eventImage = event && event.cr676_image ? `data:image/jpeg;base64,${event.cr676_image}` : this.state.images[(Math.floor(Math.random() * 12))].url;
        if (Object.keys(event).length !== 0) {
          eventImage = event && event.cr676_image ? `data:image/jpeg;base64,${event.cr676_image}` : randomImages[event.evtech_eventcategory] ? randomImages[event.evtech_eventcategory][index % randomImages[event.evtech_eventcategory].length] : randomImages.random[index % randomImages.random.length];
        }

        values.push(
          <Col className="col-xxl-4" key={index} xs="12" sm="12" md="12" lg="4" xl="4">
            <div className="event">
              <div className="image-outer"><img src={eventImage} alt={event.evtech_name} /></div>
              <h5>{event.evtech_name}</h5>
              <div className="event-des">
                <p><Icon name="calendar" />  {event.evtech_startdate ? moment(event.evtech_startdate).format("MMM DD, YYYY") : ''} {event.evtech_enddate ? "- " + moment(event.evtech_enddate).format("MMM DD, YYYY") : ''}</p>
                <p><Icon name="clock-o" /> {event.evtech_startdate ? moment.utc(event.evtech_startdate).format("hh:mm A") : ''} {event.evtech_enddate ? "- " + moment.utc(event.evtech_enddate).format("hh:mm A") : ''}</p>
                <p><Icon name="map-marker" /> {event.evtech_venueid ? event.evtech_venueid.evtech_name : ''}</p>
                {/*<p>{event.evtech_description}</p> */}
              </div>
              <Link to={{ pathname: `event-detatil/${event.evtech_eventid}`, state: { eventImage } }}>   <DefaultButton color="link" name="Read More"
              >
              </DefaultButton> </Link>
            </div>
          </Col>
        )
        return true
      })
      return values;
    }
  }


  getSerach = (value) => {
    let { allElements } = this.state;

    allElements['search'] = {
      filter: 'contains',
      eq: 'eq',
      id: value,
      operator: "and"
    }
    this.setState({ resetFilter: false });
    this.callFilter(allElements)

  }


  render() {

    let {
      date,
      getEventCategories,
      allCatgoriesChecked,
      allEvents,
      resetFilter
    } = this.state;
    return (
      <div className="dashboard">

        <Container fluid={true}>
          <div className="dashboard-content">
            <div className="dashboard-content-left">

              <div className="dashboard-heading">
                <div className="header-inner">
                  <span className="section-icon"><Icon name="building" /></span>
                  <h4>{localStorage.getItem("name")}</h4>
                </div>
                {/* <div className="events-category">
                <EventsCategories
                allCatgoriesChecked={allCatgoriesChecked}
                get_event_categories={getEventCategories}
                sortByCategory={this.sortByCategory}/>
              </div> */}
              </div>
              <div>
                <DefaultButton color="link" onClick={this.resetFilter} className="no-padding" name="Clear Filter" />
              </div>
              <div className="calender-outer">
                <h6>Looks Event For</h6>
                <EventCalendar date={date} onChange={this.onChange} />
              </div>

              <div className="events-category">
                <h6>Search</h6>
                <FormGroup>
                  <div>
                    <SearchInput
                      resetFilter={resetFilter}
                      getSerach={this.getSerach} />
                  </div>
                </FormGroup>
                <h6>Category</h6>
                <FormGroup>
                  <div>
                    <EventsCategories
                      allCatgoriesChecked={allCatgoriesChecked}
                      get_event_categories={getEventCategories}
                      sortByCategory={this.sortByCategory} />
                  </div>
                </FormGroup>

              </div>

            </div>

            <div className="dashboard-content-right">

              <div className="slider-outer">
                {this.featureEvents()}

                {/* <FeaturedEvents images={this.state.images}/> */}
              </div>
              {allEvents ? (

                <div className="slider-outer all-events">
                  <h3>All Events</h3>
                  {allEvents.length > 0 ? (
                    <Row>
                      {this.allEvents()}
                    </Row>) : "No Events Found"}

                </div>
              ) : ""}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("ALLSTATEALLSTATEALLSTATE==================",state);
  return {
    get_all_events: state.eventReducer.get_all_events.value,
    get_sorted_events: state.eventReducer.get_sorted_event && state.eventReducer.get_sorted_event.value,
    get_event_categories: state.eventReducer.get_event_categories,
    submit_request: state.eventReducer.submit_request,
    get_featured_events: state.eventReducer.get_featured_events && state.eventReducer.get_featured_events.value,
    business_unit: state.authReducer?.submit_agency_code?.data?.value
  }

}


const mapDispatch = (dispatch) => ({
  getAllEvents: () => dispatch(getAllEvents()),
  getFeaturedCategories: (businessUnitId) => dispatch(getFeaturedCategories(businessUnitId)),
  getAllEventsSorted: (rest, allCatgoriesChecked) => dispatch(getAllEventsSorted(rest, allCatgoriesChecked)),
  getEventCategoriesFn: user => dispatch(getEventCategories(user)),
  submitRequestFn: postRequest => dispatch(submitRequest(postRequest))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(EventsList));
