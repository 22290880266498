/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import validator from 'validator';
import { get } from 'lodash'

import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Icon } from './../../../UI/atoms/Icon';

import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";

import { getEventDetails, getEventCategories, submitRequest, getClearEventDetails } from "../../../../actions/EventActions";
import { DefaultInput, DefaultButton } from '../../../UI/atoms/index';
import { SITE_KEY } from '../../../../utils/constants';


// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

class EventDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      activeTab: "1",
      modal: false,
      openModal: false,
      getEventCategories: [],
      googleCapture: "",
      images: [
        { name: "Event 1", url: "https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 2", url: "https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 3", url: "https://images.pexels.com/photos/1047442/pexels-photo-1047442.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 4", url: "https://images.pexels.com/photos/1684187/pexels-photo-1684187.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 5", url: "https://images.pexels.com/photos/1449791/pexels-photo-1449791.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 6", url: "https://images.pexels.com/photos/1190297/pexels-photo-1190297.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 7", url: "https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 8", url: "https://images.pexels.com/photos/2774556/pexels-photo-2774556.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 9", url: "https://images.pexels.com/photos/2608517/pexels-photo-2608517.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 10", url: "https://images.pexels.com/photos/2399097/pexels-photo-2399097.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 11", url: "https://images.pexels.com/photos/3023317/pexels-photo-3023317.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" },
        { name: "Event 12", url: "https://images.pexels.com/photos/110472/pexels-photo-110472.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" }
      ]
    }
  }


  componentDidMount() {
    console.log('props eventDetail', this.props)
    const { getEventDetails, match: { params } } = this.props;
    this.props.getEventCategoriesFn();
    if (params && params.id) {
      getEventDetails(params.id);
    }
  }

  componentWillUnmount() {
    const { getClearEventDetails } = this.props;
    getClearEventDetails()
  }

  modalToggle = () => this.setState({ modal: !this.state.modal });


  totalTicketAllow = (string1, string2) => {

    return (
      <table className="double-conent">
        <tbody>
          <td>{string1}</td>
          <td>{string2}</td>
        </tbody>
      </table>
    );

  }


  doubleHeader = ({ string1, string2, string3 }) => {

    return (
      <table width="100%" className="innerHeading">
        <tr>
          <td>{string1}<br />{string2}</td>
          <td>{string1}<br />{string3}</td>
        </tr>
      </table>
    )
  }

  eventsData = () => {
    // alert("asdas");
    let events = [];

    events.push(
      {
        name: 'David Smith',
        email: 'dsmith@gmail.com',
        TotalTickets: this.totalTicketAllow(),
        adultTickets: "10",
        cTickets: "5",
        comment: "HEllo",
        status: "New",
        action: "s"
      });

    events.unshift({
      name: '',
      email: '',
      TotalTickets: true,
      adultTickets: true,
      cTickets: true,
      comment: '',
      status: '',
      action: ''
    });
    return events;
  }



  toggle = () => {
    this.setState({
      openModal: false,
      startDateModal: '',
      endDateModal: '',
      categoryModal: '',
      cr676_event_name: '',
      ageModal: '',
      addressModal: '',
      contactModal: '',
      detailModal: '',
      image: '',
      nameModal: '',
      emailModal: '',
      agencyCodeModal: '',
      ticketsForChildernModal: '',
      ticketsForAdultsModal: '',
      eventTargetValueModal: '',
      googleCapture: "",

      noteModal: '',


    })
  }

  handleChangeModal = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  submitRequestClick = () => {
    const { submitRequestFn, eventDetails: { cr676_event_price }, agency_detail } = this.props
    const { nameModal, emailModal, agencyCodeModal, cr676_event_name, noteModal, ticketsForChildernModal, ticketsForAdultsModal, eventTargetValueModal } = this.state;
    const params = {
      "evtech_chaperonename": nameModal,
      "emailaddress": emailModal,
      "cr676_agency_code": agencyCodeModal,
      "evtech_notes": noteModal,
      "cr676_event_name": cr676_event_name,
      "evtech_numberofchildren": !isNaN(Number.parseInt(ticketsForChildernModal, 10)) ? Number.parseInt(ticketsForChildernModal, 10) : 0,
      "evtech_actualchildren": !isNaN(Number.parseInt(ticketsForChildernModal, 10)) ? Number.parseInt(ticketsForChildernModal, 10) : 0,
      "evtech_numberofchaperones": !isNaN(Number.parseInt(ticketsForAdultsModal, 10)) ? Number.parseInt(ticketsForAdultsModal, 10) : 0,
      "evtech_actualchaperones": !isNaN(Number.parseInt(ticketsForAdultsModal, 10)) ? Number.parseInt(ticketsForAdultsModal, 10) : 0,
      "evtech_eventid@odata.bind": `evtech_events(${eventTargetValueModal})`,
      "evtech_actualtotaltickets": (!isNaN(Number.parseInt(ticketsForChildernModal, 10)) ? Number.parseInt(ticketsForChildernModal, 10) : 0) + (!isNaN(Number.parseInt(ticketsForAdultsModal, 10)) ? Number.parseInt(ticketsForAdultsModal, 10) : 0),
      "evtech_totaltickets": (!isNaN(Number.parseInt(ticketsForChildernModal, 10)) ? Number.parseInt(ticketsForChildernModal, 10) : 0) + (!isNaN(Number.parseInt(ticketsForAdultsModal, 10)) ? Number.parseInt(ticketsForAdultsModal, 10) : 0),
      "cr676_children_allocate": !isNaN(Number.parseInt(ticketsForChildernModal, 10)) ? Number.parseInt(ticketsForChildernModal, 10) : 0,
      "cr676_adult_allocate": !isNaN(Number.parseInt(ticketsForAdultsModal, 10)) ? Number.parseInt(ticketsForAdultsModal, 10) : 0,
      "cr676_total_allocated": (!isNaN(Number.parseInt(ticketsForChildernModal, 10)) ? Number.parseInt(ticketsForChildernModal, 10) : 0) + (!isNaN(Number.parseInt(ticketsForAdultsModal, 10)) ? Number.parseInt(ticketsForAdultsModal, 10) : 0),
      "agency_name": localStorage.getItem('name'),
      "business_unit_name": agency_detail?.[0]?.owningbusinessunit?.name
    }
    submitRequestFn(params)
  }

  onReCaptchaChange = value => {
    this.setState({ recaptchaToken: value }, () => {
      // this.isValidCardDetail('recaptchaToken');
    });
  };


  componentDidUpdate() {
    const { get_event_categories, submit_request } = this.props
    const { get_event_categories_State, submitRequestData } = this.state

    if (get_event_categories !== get_event_categories_State) {
      let getEventCategoriesList = []
      get_event_categories.length > 0 && get_event_categories.map((event) => {
        getEventCategoriesList.push({
          value: event.code,
          label: event.name
        })
        return true
      })

      this.setState({
        get_event_categories_State: get_event_categories,
        getEventCategories: getEventCategoriesList
      })
    }

    if (submit_request !== submitRequestData) {
      this.setState({
        submitRequestData: submit_request,
        openModal: false,
        googleCapture: ""
      })
    }
  }

  render() {
    const { eventDetails, location } = this.props;
    const {
      openModal,
      // startDateModal,
      // endDateModal,
      // categoryModal,
      // ageModal,
      // addressModal,
      // contactModal,
      // detailModal,
      googleCapture,
      getEventCategories,
      // image,
      nameModal,
      emailModal,
      agencyCodeModal,
      ticketsForChildernModal,
      ticketsForAdultsModal,
      noteModal,

    } = this.state;
    const address = [eventDetails.evtech_addressline1, eventDetails.evtech_addressline2, eventDetails.evtech_addressline3].join((' '));
    let eventImage = "";
    if (Object.keys(eventDetails).length !== 0) {
      eventImage = eventDetails && eventDetails.cr676_image ? `data:image/jpeg;base64,${eventDetails.cr676_image}` : get(location, 'state.eventImage', '');
    }

    return (

      <div className="dashboard">
        <Modal isOpen={openModal} toggle={this.toggle} className="event-popup large custom-popup modal-dialog-centered" id="ev-detail">
          <ModalHeader toggle={this.toggle}>{eventDetails && eventDetails.evtech_name}</ModalHeader>
          <ModalBody>

            {/* <Col md="6">


                  <div className="pop-event-detail"> <Icon name="calendar" /> <label>Start Date &amp; time</label> : <span className="data">{startDateModal}</span></div>
                  <div className="pop-event-detail"> <Icon name="calendar" /> <label>End Date &amp; time</label> : <span className="data">{endDateModal}</span></div>
                  <div className="pop-event-detail"> <Icon name="list" /> <label>Category</label> : 
                  <span className="data">
                    { getEventCategories.findIndex(data => Number.parseInt( data.value ,10 ) === categoryModal) !== -1 ? getEventCategories[getEventCategories.findIndex(data => Number.parseInt( data.value ,10 ) === categoryModal)].label : "" }
                    </span>
                  </div>
                  <div className="pop-event-detail"> <Icon name="users" /> <label>Age Range</label> : <span className="data">{ageModal}</span></div>
                  
                  <div className="pop-event-detail"> <Icon name="address-book" /> <label>Address</label> : <span className="data">{addressModal}</span></div>
                  <div className="pop-event-detail"> <Icon name="address-book" /> <label>Contact Details</label> : <span className="data">{contactModal}</span></div>
                  <div className="pop-event-detail"> <label>Details</label> : <span className="data">{detailModal}</span></div>

              </Col> */}

            <FormGroup>
              <Row>
                <Col lg="6">
                  <label>Name</label>
                  <DefaultInput
                    name="nameModal"
                    onChange={this.handleChangeModal}
                    value={nameModal}
                    type="text"
                    placeholder="Enter Name"
                  />
                </Col>
                <Col lg="6">
                  <label>Email</label>
                  <DefaultInput
                    name="emailModal"
                    onChange={this.handleChangeModal}
                    value={emailModal}
                    type="text"
                    placeholder="Enter Email"
                  />
                </Col>
              </Row>
            </FormGroup>


            <FormGroup>
              <Row>
                <Col lg="6">
                  <label>Agency Name</label>
                  <DefaultInput
                    name="agencyCodeModal"
                    onChange={this.handleChangeModal}
                    value={agencyCodeModal}
                    disabled
                    type="text"
                    placeholder="Agency Code"
                  />
                </Col>

                <Col lg="6">
                  <label>Quantity for Children</label>
                  <DefaultInput
                    name="ticketsForChildernModal"
                    onChange={this.handleChangeModal}
                    value={ticketsForChildernModal}
                    type="text"
                    placeholder="Quantity for Children"
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col lg="6">
                  <label>Quantity for Adults</label>
                  <DefaultInput
                    name="ticketsForAdultsModal"
                    onChange={this.handleChangeModal}
                    value={ticketsForAdultsModal}
                    type="text"
                    placeholder="Quantity for Adults"
                  />
                </Col>
                <Col lg="6">
                  <label>Note</label>
                  <DefaultInput
                    name="noteModal"
                    onChange={this.handleChangeModal}
                    value={noteModal}
                    type="text"
                    placeholder="Note (Optional)"
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>

            </FormGroup>




          </ModalBody>
          <ModalFooter>
            <ReCAPTCHA
              sitekey={SITE_KEY}
              onChange={(value) =>
                this.setState({
                  googleCapture: value
                })
              }
              onExpired={() => this.setState({
                googleCapture: ""
              })
              }
            />
            {/* <Button color="secondary" onClick={this.toggle}>Cancel</Button>{' '} */}
            <Button color="primary"
              className={googleCapture === "" || !validator.isEmail(emailModal || '') ? "display-none" : ""}
              disabled={googleCapture === "" || !validator.isEmail(emailModal || '')}
              onClick={() => {
                this.submitRequestClick()
              }}>Submit</Button>

          </ModalFooter>
        </Modal>

        <Container>
          <div className="custom-breadcrumb">
            <Breadcrumb tag="nav" listTag="div">
              <BreadcrumbItem tag="a" ><Link to="/events"> Event list</Link></BreadcrumbItem>
              <BreadcrumbItem active tag="span">{eventDetails && eventDetails.evtech_name}</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* <div className="dashboard-heading">
            <span className="section-icon">
              <Icon name="calendar"/>
            </span>
          <h4>{eventDetails && eventDetails.evtech_name}</h4>
            <span>Lorem ipsum dolor sit amet</span>
          </div> */}
          <div className="event-details">
            <Row>
              <Col xl={{ size: 4, offset: 2 }}>
                <img style={{ maxWidth: "100%", width: "100%" }} src={eventImage} alt={eventDetails.name} />
              </Col>

              <Col xl="4">
                <div className="event-summary">
                  <Row>
                    <Col xl="12">
                      <h2>{eventDetails && eventDetails.evtech_name}</h2>
                      <div className="event-summary-box">
                        <Icon name="calendar" />
                        <h6>Start Date &amp; Time:</h6>
                        <span class="data">{(eventDetails && eventDetails.evtech_startdate) ? moment.utc(eventDetails.evtech_startdate).format("MMM DD, YYYY hh:mm A") : 'N/A'}</span>

                      </div>

                      <div className="event-summary-box">
                        <Icon name="calendar" />
                        <h6>End Date &amp; Time:</h6>
                        <span class="data">{(eventDetails && eventDetails.evtech_enddate) ? moment.utc(eventDetails.evtech_enddate).format("MMM DD, YYYY hh:mm A") : 'N/A'}</span>
                      </div>

                      <div className="event-summary-box">
                        <Icon name="list" />
                        <h6>Category</h6>
                        <span class="data">
                          {getEventCategories.findIndex(data => Number.parseInt(data.value, 10) === eventDetails.evtech_eventcategory) !== -1 ? getEventCategories[getEventCategories.findIndex(data => Number.parseInt(data.value, 10) === eventDetails.evtech_eventcategory)].label : ""}

                          {/* {eventDetails && eventDetails.evtech_eventcategory} */}
                        </span>
                      </div>

                      <div className="event-summary-box">
                        <Icon name="users" />
                        <h6>Age Range</h6>
                        <span class="data">{eventDetails && eventDetails.evtech_agerange}</span>
                      </div>


                      <div className="event-summary-box">
                        <Icon name="address-book" />
                        <h6>Address:</h6>
                        <span class="data">{eventDetails && address ? address : "-"}</span>
                      </div>

                    </Col>
                    <Col xl="12">
                      {/* 
                    <div className="tickets-summary">
                      <p>RECEIVED TICKETS : {eventDetails && eventDetails.evtech_totalrequested ? eventDetails.evtech_totalrequested : 0}</p>
                      <p>ALLOCATED TICKETS : {eventDetails && eventDetails.evtech_totaldistributed ? eventDetails.evtech_totaldistributed : 0}</p>
                    </div> */}

                      {/* 
                      <div className="event-summary-box">
                        <Icon name="address-book" />
                        <h6>Contact Details:</h6>
                        <span class="data">{eventDetails && eventDetails.new_contactphone}</span>
                      </div> */}

                    </Col>
                    <Col xl="12">
                      <div className="event-summary-box details">
                        <h6>Details:</h6>
                        <span class="data">{eventDetails && eventDetails.evtech_description}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DefaultButton name="Request this Experience!" color="primary" disabled={Object.keys(eventDetails).length === 0}
                        onClick={() => {
                          this.setState({
                            openModal: true,
                            startDateModal: eventDetails.evtech_startdate ? moment(eventDetails.evtech_startdate).format("MMM DD ,YYYY hh:mm A") : '',
                            endDateModal: eventDetails.evtech_enddate ? moment(eventDetails.evtech_enddate).format("MMM DD ,YYYY hh:mm A") : '',
                            categoryModal: eventDetails.evtech_eventcategory,
                            cr676_event_name: eventDetails.evtech_name,
                            ageModal: eventDetails && eventDetails.evtech_agerange,
                            addressModal: `${eventDetails.evtech_city && eventDetails.evtech_city !== "" ? eventDetails.evtech_city : ""}${eventDetails.evtech_addressline1 === "null" ? ", " + eventDetails.evtech_addressline1 : ""}`,
                            contactModal: eventDetails.new_contactphone,
                            detailModal: eventDetails.evtech_description,
                            // image: this.state.images[(Math.floor(Math.random() * 12))].url,
                            eventTargetValueModal: eventDetails.evtech_eventid,
                            nameModal: '',
                            emailModal: '',
                            agencyCodeModal: localStorage.getItem('agencyCode'),
                            ticketsForChildernModal: '',
                            ticketsForAdultsModal: '',
                            noteModal: '',
                          })
                        }}
                      > </DefaultButton>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>




        </Container>

      </div>


    );
  }
}

const mapStateToProps = (state) => ({
  eventDetails: state.eventReducer.eventDetails,
  get_event_categories: state.eventReducer.get_event_categories,
  submit_request: state.eventReducer.submit_request,
  agency_detail: state.authReducer.submit_agency_code?.data?.value
});
const mapDispatchTpProps = (dispatch) => ({
  getEventDetails: (eventId) => dispatch(getEventDetails(eventId)),
  getEventCategoriesFn: user => dispatch(getEventCategories(user)),
  getClearEventDetails: () => dispatch(getClearEventDetails()),
  submitRequestFn: postRequest => dispatch(submitRequest(postRequest))
});


export default withRouter(connect(mapStateToProps, mapDispatchTpProps)(EventDetail));
