/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter } from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

class EventCalendar extends React.Component {

  render() {
    //console.log("TOP10EVENTS==========", this.props.get_all_events);

    return (
     
        <Calendar
          onChange={this.props.onChange}
          value={this.props.date}
        />
    );
  }
}


export default withRouter(EventCalendar);
