import React from 'react';
import { connect } from "react-redux";
import { Navbar, NavbarBrand,
  //  Nav, NavItem
   } from 'reactstrap';
import Logo from './../../../assets/images/logo-trans.png';
// import Logout from '../../UI/molecules/Logout';

class Header extends React.Component {
  state = {
    isLoader: false,
  };

  render() {
    // const {eventDetails} = this.props
    return (
      <header className="user-header">
        <Navbar light expand="md">
          <NavbarBrand href="/">
            <img src={Logo} alt="" /> 
          </NavbarBrand>
          {/* <h5 className="event-name">{eventDetails && eventDetails.evtech_name ? eventDetails.evtech_name : localStorage.getItem("name")}</h5> */}
        </Navbar>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  eventDetails: state.eventReducer.eventDetails,
});


export default connect(mapStateToProps, null)(Header);


// export default Header;
