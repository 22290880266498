import { callApi, callDynamicApi, callDynamicServerApi } from '../utils/apiCaller';
import { toast } from 'react-toastify';
import { WEB_URL } from '../utils/constants';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_FRESH_DETAILS = 'USER_FRESH_DETAILS';
export const LOGIN_TOKEN = 'LOGIN_TOKEN';
export const SET_INITIAL_AUTH_NEW = 'SET_INITIAL_AUTH_NEW';
export const SUCCESS_CHECK_EMAIL = 'SUCCESS_CHECK_EMAIL';
export const ERROR_CHECK_EMAIL = 'ERROR_CHECK_EMAIL';
export const SUBMIT_AGENCY_CODE = 'SUBMIT_AGENCY_CODE';

/*
  set initial auth  (ON LOGOUT)
*/
export const set_initial_auth = () => {
  let payload = true;
  return (dispatch) => {
    return Promise.all([dispatch({ type: SET_INITIAL_AUTH_NEW, payload })]).then(() => '');
  };
};

/*
 set Initial Auth
*/
export const setInitialAuth_ = (payload) => {
  return {
    type: USER_LOGIN,
    payload,
  };
};

export const setInitialAuth = (payload) => {
  return (dispatch) => dispatch(setInitialAuth_(payload));
};

/*
 login User
*/
export const loginUser_ = (payload) => {
  return {
    type: USER_LOGIN,
    payload,
  };
};

export const loginUser = (data) => {
  return (dispatch) => {
    callApi('auth-login/', 'POST', data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        getDynamicUser(dispatch, response.data);
        return dispatch(loginUser_(response));
      }
    });
  };
};

const getDynamicUser = (dispatch, userData) => {
  callDynamicApi('accounts(' + userData.guid + ')', 'GET', false).then((response) => {
    // console.log('newObj>>', response.data);
    return dispatch({
      type: 'STORE_DYNAMICS_USER_DETAILS',
      payload: response.data,
    });
    // return dispatch(loginUser_(newObj))
  });
};

/*
 login To
*/
export const loginToken_ = (payload) => {
  return {
    type: LOGIN_TOKEN,
    payload,
  };
};

export const loginToken = (data) => {
  // console.log('data>>>>', data);
  return (dispatch) => {
    callDynamicApi('41dce816-24cd-4d36-a93b-ca75247413e2/oauth2/token', 'POST', data, true)
      .then((response) => {
        if (response) {
          return dispatch(loginToken_(response.data));
        }
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };
};

/*
 fetch User
*/
export const fetchUser_ = (payload) => {
  return {
    type: USER_FRESH_DETAILS,
    payload,
  };
};

export const fetchUser = (data) => {
  return (dispatch) => {
    callApi('get-user/', 'GET', data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(fetchUser_(response));
      }
    });
  };
};

/*
SignUp user
 */

export const signUp = (data) => {
  let params = {
    email: data.emailaddress1,
    password: data.evtech_password,
  };
  return (dispatch) => {
    callApi('create-user/', 'POST', params)
      .then((response) => {
        // console.log('response>', response);
        return response;
      })
      .then((res) => {
        // console.log('res', res);
        if (res.code === 201) {
          dynamicSignUp(data, res.data, dispatch);
        }
      });
  };
};

export const checkEmail = (data) => {
  let params = {
    email: data.emailaddress1
  }
  return (dispatch) => {
    callApi('email-check/', 'POST', params)
      .then((response) => {
        // console.log('response>', response);
        if (response.code === 200) {
          dispatch({ type: SUCCESS_CHECK_EMAIL, response })
        } else {
          dispatch({ type: ERROR_CHECK_EMAIL, response })
        }
      })
  }
}

const dynamicSignUp = (data, userData, dispatch) => {
  data['telephone1'] = data['extension'] + data['telephone1'];
  delete data['confirm_password'];
  delete data['cr676_alternate_tickets_collection1'];
  delete data['extension'];
  callDynamicApi('accounts', 'POST', data).then((response) => {
    if (response.status === 204) {
      if (response.headers['odata-entityid']) {
        let guid = response.headers['odata-entityid'].split('(')[1].split(')')[0];
        updateUser(guid, userData, dispatch);
      }
    }
  });
};

const updateUser = (guid, userData, dispatch) => {
  let params = {
    guid: guid,
  };
  callApi('update-user/' + userData.id + '/', 'PUT', params)
    .then((response) => {
      // console.log('response>', response);
      return response;
    })
    .then((res) => {
      // console.log('res', res);
      // return res;
      return dispatch({
        type: 'UPDATE_USER_AFTER_DYNAMICS',
        payload: res,
      });
    });
};

export const forgetPassword = (data) => {
  let params = {
    email: data.email,
    web_url: WEB_URL,
  };
  return callApi('forget-password/', 'POST', params)
    .then((response) => {
      if (response) {
        toast.success('Password Reset Link has been sent to the Email ID');
      }
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};

export const resetPassword = (data, history) => {
  let params = {
    password: data.password,
    token: data.token,
  };
  callApi('reset-password/', 'POST', params)
    .then((response) => {
      // console.log(response);
      toast.success('Password Has been reset successfully');
      history.push('/');
    })
    .catch((error) => {
      console.error(error);
      return Promise.reject(error);
    });
};


export const submitRequestAgencyCode = (data) => {
  return async (dispatch) => {

    return callDynamicServerApi(`accounts?$filter=cr676_agency_code eq '${data.agency_code}' &$select=name, cr676_agency_code, cr676_location &$expand=owningbusinessunit`, 'GET').then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        if (response.data.value.length === 0) {
          toast.error('Sorry location is not found');
        }
        return dispatch({
          type: SUBMIT_AGENCY_CODE,
          payload: response,
        });
      }
    });
  };
};