import React from 'react';
import pageNotFoundImage from './../../../assets/images/404.jpg';

class PageNotFound extends React.Component {
  componentDidMount() {
    const { history } = this.props;
    setTimeout(() => {
      history.push('/');
    }, 3000);
  }

  render() {
    return (
      <div className="wrapper-login">
        <div className="container center-align">
          <h2>Page not Found !!</h2>
          <p>Redirecting you to the Dashboard ...</p>
          <img alt="page not found" src={pageNotFoundImage} />
        </div>
      </div>
    );
  }
}

export default PageNotFound;
