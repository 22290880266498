import { createStore, compose, applyMiddleware } from 'redux';
// import { createEpicMiddleware } from 'redux-observable';
// import { rootEpic } from './reducers/rootReducer'
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
// const epicMiddleware = createEpicMiddleware();
const initialState = {}
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export default function configureStore(initialState = {}) {
// }

function saveToLocalStorage(state) {
  try {
      const serializedState = JSON.stringify(state.authReducer)
      localStorage.setItem('authReducer', serializedState)
  } catch (ex) {
      console.log('error: ', ex)
  }
}

const persistedState = loadFromLocalStorage();
const configureStore = createStore(rootReducer, persistedState, composeEnhancer(applyMiddleware(thunk)));

configureStore.subscribe(() => saveToLocalStorage(configureStore.getState()))

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('authReducer')
        if (serializedState === null) 
            return {}
    
            return { authReducer: JSON.parse(serializedState) }
    } catch (ex) {
        console.log('error: ', ex)
    }
}

export default configureStore
