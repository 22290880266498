const randomImages = {
    861560000: [              //hockey
        'http://np.seasiafinishingschool.com:7082/media/agency/Hockey/Hockey.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Hockey/Hockey2.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Hockey/Hockey3.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Hockey/Hockey4.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Hockey/Hockey5.jpg',
    ],
    807710000: [                 //theatre
        'http://np.seasiafinishingschool.com:7082/media/agency/Theatre/Kid Theatre.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Theatre/Theatre.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Theatre/young-people-eating-popcorn-in-movie-theater-PCKLMKX.jpg',
    ],
    807710002: [                //concert
        'http://np.seasiafinishingschool.com:7082/media/agency/Concerts/Concert.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Concerts/Concert2.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Concerts/bassist-pop-rock-during-a-performance-at-a-concert-P3EVYQ6.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Concerts/concert-crowd-PNSTMPE.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Concerts/music-concert-PPJF3CB.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Concerts/people-dancing-at-concert-75SQ9XG.jpg',
    ],
    861560001: [               //football
        'http://np.seasiafinishingschool.com:7082/media/agency/Football/american-football-ball-A27HUTF.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Football/american-football-game-PA7M4R5.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Football/american-football-player-PXMWTX7.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Football/american-football-player-QNLFEBG.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Football/Stadium.jpg',
    ],
    861560003: [              //baseball
        'http://np.seasiafinishingschool.com:7082/media/agency/Baseball/baseball-and-glove-PWMPTAY.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Baseball/baseball-ball-SCTWPPH.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Baseball/baseball-bat-and-ball-PMGK8ZA.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Baseball/Kid-baseball.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Baseball/old-baseball-and-base-in-dirt-infield-PTFE25C.jpg',
    ],
    861560004: [          //soccer
        'http://np.seasiafinishingschool.com:7082/media/agency/Soccer/close-up-of-soccer-ball-on-playing-field-GXF296H.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Soccer/close-up-of-soccer-player-with-ball-on-field-PEZ7S4R.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Soccer/foot-on-soccer-ball-PCP3AJD.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Soccer/low-section-of-soccer-player-and-ball-on-field-26QD9UV.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Soccer/soccer-goal-P7JCDUM.jpg',
    ],
    861560005: [           //Dance
        'http://np.seasiafinishingschool.com:7082/media/agency/Dance/ballerina-wearing-ballet-shoes-XHUJF7T.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Dance/ballet-student-exercising-in-ballet-costume-BKDV9GH.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Dance/legs-of-ballerina-woman-in-white-tutu-and-pointe-s-XGSFDXM.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Dance/the-group-of-modern-ballet-dancers-contemporary-ar-9LRVWCH.jpg',
    ],
    random: [
        'http://np.seasiafinishingschool.com:7082/media/agency/Dance/the-group-of-modern-ballet-dancers-contemporary-ar-9LRVWCH.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Soccer/soccer-goal-P7JCDUM.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Baseball/Kid-baseball.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Football/Stadium.jpg',
        'http://np.seasiafinishingschool.com:7082/media/agency/Concerts/people-dancing-at-concert-75SQ9XG.jpg',
    ]

}

export default randomImages