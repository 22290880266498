import React from 'react';
import { withRouter } from 'react-router-dom';
import { Header } from '.';

class GuestMain extends React.Component {
  state = {
    isLoader: false,
  };

  render() {
    const { children } = this.props;
    let current_loc;
    if (this.props.location.pathname) {
      current_loc = this.props.location.pathname;
    }
    return (
      <>
        {current_loc === '/signup' && <Header />}
        <section>{children}</section>
        {/*<Footer />*/}
      </>
    );
  }
}

export default withRouter(GuestMain);
