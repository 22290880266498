const initialState = {
  get_event_categories: {},
  get_featured_events: {},
  get_all_events: {},
  get_sorted_event: {},
  submit_request: {},
  eventDetails: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INITIAL_SIMPLE':
      return initialState;

    case 'GET_EVENT_CATEGORIES':
      state.get_event_categories = action.payload;
      return { ...state };
    case 'GET_EVENT_FEATURED_CATEGORIES':
      state.get_featured_events = action.payload;
      return { ...state };
    case 'GET_ALL_EVENTS':
      state.get_all_events = action.payload;
      return { ...state };
    case 'GET_EVENTS_SORTED':
      state.get_sorted_event = action.payload;
      return { ...state };
      
    case 'GET_EVENT_DETAILS':
      return { ...state, eventDetails: action.payload }

    case 'SUBMIT_REQUEST_EVENTS':
      state.submit_request = action.payload;
      return { ...state };
  
      
    default:
      return state;
  }
};
