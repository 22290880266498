import axios from 'axios';
import { API_URL, DYNAMICS_URL, DYNAMICS_LOGIN_URL, DYNAMICS_SERVER_URL } from './constants';
const qs = require('querystring');

export const callApi = (endpoint, method = 'POST', body, fileToUpload = false) => {
  localStorage.setItem('loader', true);
  let headers = {};
  let authToken = false;
  let baseUrl = `${API_URL}${endpoint}`;

  let localStorage_token = localStorage.getItem('token');
  if (localStorage_token) {
    authToken = localStorage_token;
  }


  if (method) {
    // headers['X-Requested-With'] = 'XMLHttpRequest';
    // headers['Access-Control-Allow-Origin'] = '*';
    // headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
  }

  if (fileToUpload) {
    headers['Content-Type'] = 'multipart/form-data';
  } else {
    headers['Content-Type'] = 'application/json';
  }

  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }

  return axios({
    method: method,
    url: baseUrl,
    data: body,
    headers: headers,
  })
    .then((response) => {
      localStorage.setItem('loader', false);
      return response.data;
    })
    .catch((err) => err);
};

export const callDynamicApi = (endpoint, method = 'POST', body, login = false) => {
  localStorage.setItem('loader', true);
  let headers = {};
  let authToken = false;
  let baseUrl;
  let params;
  // axios.defaults.withCredentials = true;

  if (method) {
    // headers['X-Requested-With'] = 'XMLHttpRequest';
    // headers['Access-Control-Allow-Origin'] = '*';
    // headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
  }
  if (login) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
    baseUrl = `${DYNAMICS_LOGIN_URL}${endpoint}`;
    params = qs.stringify(body);
  } else {
    baseUrl = `${DYNAMICS_URL}${endpoint}`;
    headers['Content-Type'] = 'application/json';
    let localStorage_token = localStorage.getItem('dynamic_token');
    if (localStorage_token) {
      authToken = localStorage_token;
    }
    params = body;
  }
  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }
  return axios({
    method: method,
    url: baseUrl,
    data: params,
    headers: headers,
  })
    .then((response) => {
      localStorage.setItem('loader', false);
      return response;
    })
    .catch((err) => err);
};


export const callDynamicServerApi = (endpoint, method = 'POST', body) => {
  localStorage.setItem('loader', true);
  let headers = {};
  let authToken = false;
  let baseUrl;
  let params;
  // axios.defaults.withCredentials = true;

  if (method) {
    // headers['X-Requested-With'] = 'XMLHttpRequest';
    // headers['Access-Control-Allow-Origin'] = '*';
    // headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
  }

  baseUrl = `${DYNAMICS_SERVER_URL}${endpoint}`;
  headers['Content-Type'] = 'application/json';
  let localStorage_token = localStorage.getItem('dynamic_token');
  if (localStorage_token) {
    authToken = localStorage_token;
  }
  params = body;
  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }
  return axios({
    method: method,
    url: baseUrl,
    data: params,
    headers: headers,
  })
    .then((response) => {
      localStorage.setItem('loader', false);
      return response;
    })
    .catch((err) => err);
};