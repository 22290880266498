/*eslint eqeqeq: "off"*/
import React from 'react';
import { withRouter } from "react-router-dom";
import 'react-owl-carousel2/src/owl.carousel.css'; //Allows for server-side rendering.
import Select from 'react-select';


class EventsCategories extends React.Component {

  render() {
    const { allCatgoriesChecked, get_event_categories, sortByCategory } = this.props;
    return (
      <>
      <Select
        value={allCatgoriesChecked}
        onChange={(selectedOption ) => {sortByCategory(selectedOption)}}
        options={get_event_categories}
        isMulti 
      />
    
        {/* {this.eventCategories()} */}
      </>         
    );
  }
}



export default withRouter(EventsCategories);
