import React from 'react';

class Footer extends React.Component {
  state = {
    isLoader: false,
  };

  render() {
    return (
      <footer>
        <span>Copyright © 2020 Kidsupfront. All rights reserved.</span>
      </footer>
    );
  }
}

export default Footer;
